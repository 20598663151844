import classNames from 'classnames';
import React, { ChangeEventHandler, ForwardedRef } from 'react';
import ErrorText from './ErrorText';

interface TextAreaProps {
  id: string;
  label: string;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  className?: string;
  disabled?: boolean;
  error?: string;
  dataTestId?: string;
}

const TextArea = React.forwardRef(
  (props: TextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const {
      defaultValue,
      disabled,
      id,
      label,
      name,
      placeholder,
      onChange,
      className,
      error,
      dataTestId,
    } = props;
    return (
      <div>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-1">
          <textarea
            ref={ref}
            rows={4}
            name={name}
            id={id}
            className={classNames(
              ' shadow-sm focus:ring-blue-600 focus:border-blue-600 block w-full sm:text-sm border-gray-300 rounded-md ',
              !disabled &&
                ' disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ',
              error && ' border-red-500',
              className,
            )}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            data-cy={dataTestId}
          />
          {error && <ErrorText>{error}</ErrorText>}
        </div>
      </div>
    );
  },
);

TextArea.defaultProps = {
  defaultValue: '',
  placeholder: '',
  onChange: undefined,
  className: undefined,
  disabled: false,
  error: undefined,
  dataTestId: undefined,
};

export default TextArea;
