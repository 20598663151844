import { UserTableRow } from 'api/generated';
import LoadingTable from 'components/LoadingTable';
import Table from 'components/Table';
import { useUserContext } from 'context/UserContext';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  useDeleteOrgMemberMutation,
  useGetOrgMembers,
  useMakeAdminMutation,
  useRemoveAdminMutation,
} from './queries';

const OrgMemberTable: React.FC = () => {
  const { auth0User } = useUserContext();
  const { data: users, refetch: refetchUsers } = useGetOrgMembers();
  const { mutate: deleteUser } = useDeleteOrgMemberMutation();
  const { mutate: makeAdmin } = useMakeAdminMutation();
  const { mutate: removeAdmin } = useRemoveAdminMutation();
  const loadingTable = useMemo(() => <LoadingTable />, []);
  return (
    <>
      {users && (
        <Table
          idKey="row"
          columns={users.columns}
          data={users.data}
          total={users.meta.page.total ?? users.data.length}
          actions={[
            {
              key: 'promote',
              label: 'Make admin',
              action: (row: UserTableRow) => {
                makeAdmin(
                  {
                    updateUserRoleRequest: { userId: row.data.userId },
                  },
                  {
                    onSuccess: () => {
                      toast.success(`${row.data.email} promoted to admin`);
                      refetchUsers();
                    },
                    onError: () => {
                      toast.error(
                        `Unable to promote user ${row.data.email}. Try Again.`,
                      );
                    },
                  },
                );
              },
              dynamicHide: (row: UserTableRow) =>
                Boolean(row?.data?.roles?.includes('admin')),
            },
            {
              key: 'demote',
              label: 'Remove admin',
              action: (row: UserTableRow) => {
                removeAdmin(
                  {
                    userId: row.data.userId,
                  },
                  {
                    onSuccess: () => {
                      toast.success(
                        `Admin privileges revoked for ${row.data.email}`,
                      );
                      refetchUsers();
                    },
                    onError: () => {
                      toast.error(
                        `Unable to remove admin privileges user ${row.data.email}. Try Again.`,
                      );
                    },
                  },
                );
              },
              dynamicHide: (row: UserTableRow) =>
                !row?.data?.roles?.includes('admin') ||
                row.data.userId === auth0User?.sub,
            },
            {
              key: 'delete',
              label: 'Delete User',
              danger: true,
              dynamicHide: (row: UserTableRow) =>
                row.data.userId === auth0User?.sub,
              action: (row: UserTableRow) => {
                deleteUser(
                  { userId: row.data.userId },
                  {
                    onSuccess: () => {
                      toast.success(
                        `Successfully deleted user ${
                          row.data.name || row.data.email
                        }`,
                      );
                      refetchUsers();
                    },
                    onError: () => {
                      toast.error(
                        `Unable to delete user ${
                          row.data.name || row.data.email
                        }. Try Again.`,
                      );
                    },
                  },
                );
              },
            },
          ]}
          // activePage={pagination.page}
          // pageSize={pagination.size}
          // showRowCount
          // setPageSize={setPageSize}
          // loadPage={loadPage}
          // onClickImagePreview={setSidebarData}
        />
      )}
      {!users && loadingTable}
    </>
  );
};

export default OrgMemberTable;
