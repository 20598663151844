import { ArchiveBoxIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button';
import Main from 'components/Main';
import { NavigateToDatasetButton } from 'components/NavigateToDatasetButton';
import PageHeader from 'components/PageHeader';
import TextInput from 'components/TextInput';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  useCreateDynamicTagMutation,
  useGetCategoryById,
  useValidateDynamicTagNameMutation,
} from 'queries/dynamic-tags';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  inputValidationCallbackFactory,
  isValidationResponseInvalid,
} from 'utils/InputValidationUtils';
import EmptyState from '../components/EmptyState';
import TextPrompts from '../components/TextPrompts';
import VisualPrompts from '../components/VisualPrompts';

const CreateDynamicTag = function CreateDynamicTag() {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const { data: category } = useGetCategoryById(categoryId);

  const [textPrompts, setTextPrompts] = useState<string[]>([]);
  const [visualPrompts, setVisualPrompts] = useState<any>([]);
  const [isEditingBody, setIsEditingBody] = useState<boolean>(false);
  const [dynamicTagName, setDynamicTagName] = useState<string>('');
  const [shouldReplaceFirstPrompt, setShouldReplaceFirstPrompt] =
    useState<boolean>(false);
  const [hasClickedNameInput, setHasClickedNameInput] =
    useState<boolean>(false);
  const nameInputRef = useRef<HTMLInputElement>(null);

  const { mutate: createDynamicTag } = useCreateDynamicTagMutation();
  const {
    mutate: validateDynamicTagName,
    isLoading: isDynamicTagNameValidating,
    data,
  } = useValidateDynamicTagNameMutation();

  const isDynamicTagNameInvalid = isValidationResponseInvalid(data);
  const inputValidation = inputValidationCallbackFactory<string>(
    dynamicTagName,
    validateDynamicTagName,
    (value: string) => ({ categoryId, validateNameRequest: { name: value } }),
    [category],
  );

  const handleCancelEdit = () => {
    setTextPrompts([]);
    setVisualPrompts([]);
    setIsEditingBody(false);
    navigate(`/dynamic-tag-categories/${categoryId}`);
  };

  const handleChangeDynamicTagName = (value: string) => {
    setIsEditingBody(true);
    setDynamicTagName(value);
  };

  const breadcrumbs = [
    {
      label: 'Dynamic tag categories',
      to: '/dynamic-tag-categories',
    },
    {
      label: `${category?.name || 'Category'}: Dynamic tags`,
      to: `/dynamic-tag-categories/${categoryId}`,
    },
    {
      label: 'New dynamic tag name',
      to: '',
    },
  ];

  const areChangesValid =
    (textPrompts.length || visualPrompts.length) && dynamicTagName.trim();
  const arePromptsEmpty = !textPrompts.length && !visualPrompts.length;
  const areAllFieldsEmpty = arePromptsEmpty && !dynamicTagName.trim();

  const parsedVisualPrompts = visualPrompts.map((visualPrompt) => ({
    path: visualPrompt.path,
    coactiveImageId: visualPrompt.coactiveImageId,
  }));
  const createDynamicTagFn = async (): Promise<void> =>
    new Promise((resolve, reject) => {
      if (!categoryId || !areChangesValid) {
        reject();
      }

      createDynamicTag(
        {
          categoryId: categoryId!,
          createDynamicTagRequest: {
            name: dynamicTagName,
            textPromptData: textPrompts,
            visualPromptData: parsedVisualPrompts,
          },
        },
        {
          onSuccess: () => {
            navigate(`/dynamic-tag-categories/${categoryId}`);
            toast.success('Success! Your dynamic tag will be added!');
          },
          onError: () => {
            toast.error('Dynamic tag could not be created. Please try again.');
          },
        },
      );
    });

  const handleOutsideClick = () => {
    if (!hasClickedNameInput) return;

    const canAddTagNameAsPrompt = dynamicTagName.trim().length > 0;
    const trimmedDynamicTagName = dynamicTagName.trim();

    if (canAddTagNameAsPrompt) {
      if (!shouldReplaceFirstPrompt) {
        setTextPrompts([trimmedDynamicTagName, ...textPrompts]);
        setShouldReplaceFirstPrompt(true);
      } else {
        const newTextPrompts = [trimmedDynamicTagName, ...textPrompts.slice(1)];
        setTextPrompts(newTextPrompts);
      }
    }

    setHasClickedNameInput(false);
  };

  const title = (
    <div className="flex items-start justify-between w-full">
      <div className="space-y-4 w-1/2 pr-8">
        <TextInput
          dataTestId="dynamic-tag-create-page-name-input"
          ref={nameInputRef}
          containerClassName="w-full"
          inputClassName="!text-2xl"
          id="dynamic-tag-name"
          name="dynamic-tag-id"
          onChange={(e) => handleChangeDynamicTagName(e.target.value)}
          onInputClick={() => setHasClickedNameInput(true)}
          value={dynamicTagName}
          placeholder="New dynamic tag name"
          validation={inputValidation}
        />
        {/* TODO Explore look for multiple dataset badges */}
        <div className="space-x-2">
          {category?.datasets.map((dataset) => (
            <NavigateToDatasetButton
              key={dataset.id}
              path={`/datasets/${dataset?.id || ''}`}
              datasetName={dataset.name || 'Dataset'}
            />
          ))}
        </div>
      </div>
      <div className="space-x-2">
        <Button buttonStyle="secondary" onClick={handleCancelEdit}>
          Cancel
        </Button>
        {isEditingBody && !areAllFieldsEmpty && (
          <Button
            dataTestId="dynamic-tag-create-page-submit-button"
            disabled={
              !areChangesValid ||
              isDynamicTagNameValidating ||
              isDynamicTagNameInvalid
            }
            buttonStyle="primary"
            onClick={createDynamicTagFn}
          >
            Save changes
          </Button>
        )}
      </div>
    </div>
  );

  useOutsideClick(nameInputRef, handleOutsideClick);

  const handleRemoveNamePrompt = (idx: number) => {
    if (idx === 0) {
      setShouldReplaceFirstPrompt(false);
    }
  };

  return (
    <Main>
      <div className="max-w-8xl mx-auto">
        <PageHeader breadcrumbs={breadcrumbs} title={title} />
        <div className="my-8 flex h-40 gap-8">
          <div className="w-1/2">
            <TextPrompts
              setIsEditingPrompts={setIsEditingBody}
              prompts={textPrompts}
              setPrompts={setTextPrompts}
              dataTestId="dynamic-tag-create-page-text-prompts"
              onPromptClick={
                textPrompts.includes(dynamicTagName)
                  ? handleRemoveNamePrompt
                  : undefined
              }
            />
          </div>
          <div className=" w-1/2">
            <VisualPrompts
              dataTestId="dynamic-tag-create-page-visual-prompts"
              datasetId={category?.datasets[0].id!}
              setIsEditingPrompts={setIsEditingBody}
              visualPrompts={visualPrompts}
              setVisualPrompts={setVisualPrompts}
            />
          </div>
        </div>
        {arePromptsEmpty && (
          <div className="w-full mt-16 border-t border-gray-200">
            <EmptyState
              iconClassName="h-6 w-6 text-gray-400"
              Icon={ArchiveBoxIcon}
              message=" Save text and/or visual prompts to see top content for this dynamic tag."
            />
          </div>
        )}
      </div>
    </Main>
  );
};

export default CreateDynamicTag;
