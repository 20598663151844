import { ImageSizeEnum } from 'api/generated';
import ProtectedImage from 'components/Image/ProtectedImage';
import { TableRow } from 'components/Table/types';
import React from 'react';
import Image from 'components/Image/index';

const ImagePreview: React.FC<{
  value: string;
  row: TableRow;
  onClickImagePreview?: (data: TableRow) => void;
}> = ({ value, row, onClickImagePreview }) => {
  let image = <div />;
  if (row.asset?.previewImages?.thumb?.url) {
    image = (
      <ProtectedImage
        url={row.asset.previewImages.thumb.url}
        sensitive={row.asset.sensitive}
        coactiveImageId={row.asset.coactiveImageId}
        rounded
        alt="row thumbnail"
        size={ImageSizeEnum.Thumb}
      />
    );
  } else if (value) {
    image = <Image rounded src={value} alt="row thumbnail" />;
  } else {
    return null;
  }
  return (
    <button
      type="button"
      onClick={() => onClickImagePreview?.(row)}
      className="h-8 w-8 ml-4 scale-1 hover:scale-[1.75] transition-all "
    >
      {image}
    </button>
  );
};

ImagePreview.defaultProps = { onClickImagePreview: undefined };

export default ImagePreview;
