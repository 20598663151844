import React from 'react';

const Status: React.FC<{
  status: string;
  className: string;
}> = function Status({ status, className }) {
  return <span className={className}>{status}</span>;
};

export default Status;
