/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConceptLabelRequest,
    ConceptLabelRequestFromJSON,
    ConceptLabelRequestFromJSONTyped,
    ConceptLabelRequestToJSON,
} from './ConceptLabelRequest';

/**
 * A request to add, modify, and delete labels
 * @export
 * @interface UpdateConceptVersionLabelsRequest
 */
export interface UpdateConceptVersionLabelsRequest {
    /**
     * Labels to create/update/delete
     * @type {Array<ConceptLabelRequest>}
     * @memberof UpdateConceptVersionLabelsRequest
     */
    labels: Array<ConceptLabelRequest>;
}

export function UpdateConceptVersionLabelsRequestFromJSON(json: any): UpdateConceptVersionLabelsRequest {
    return UpdateConceptVersionLabelsRequestFromJSONTyped(json, false);
}

export function UpdateConceptVersionLabelsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateConceptVersionLabelsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labels': ((json['labels'] as Array<any>).map(ConceptLabelRequestFromJSON)),
    };
}

export function UpdateConceptVersionLabelsRequestToJSON(value?: UpdateConceptVersionLabelsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'labels': ((value.labels as Array<any>).map(ConceptLabelRequestToJSON)),
    };
}

