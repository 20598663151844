import React from 'react';
import { CircleStackIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

interface NavigateToDatasetButtonProps {
  datasetName: string;
  path: string;
  dataTestId?: string;
}

export const NavigateToDatasetButton: React.FunctionComponent<NavigateToDatasetButtonProps> =
  function NavigateToDatasetButton({ datasetName, path, dataTestId }) {
    const navigate = useNavigate();

    return (
      <Button
        className="p-4 w-auto"
        onClick={() => navigate(path)}
        buttonStyle="secondary"
        padding="py-1"
        color="bg-slate-100 hover:bg-slate-200"
        icon={CircleStackIcon}
        size="small"
      >
        <span data-cy={dataTestId}>{datasetName}</span>
      </Button>
    );
  };

NavigateToDatasetButton.defaultProps = {
  dataTestId: undefined,
};
