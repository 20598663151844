import { XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import Button from 'components/Button';
import { SelectedOption } from 'components/Combobox';
import ConceptCombobox from 'components/DatasetSearch/DatasetTextSearch/ConceptCombobox';
import CustomMetadata from 'components/DatasetSearch/DatasetTextSearch/CustomMetadata';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import ErrorText from 'components/ErrorText';
import IconButton from 'components/IconButton';
import SearchContainer from 'components/SearchContainer';
import { FeatureFlag } from 'feature_flags/FeatureFlagConfig';
import { evaluateFlag } from 'feature_flags/FeatureFlags';
import { useGetDatasetById } from 'queries/datasets';
import { useFetchMetadataFilters } from 'queries/metadata-filters';
import React, { useEffect, useMemo, useRef } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from 'tailwindcss/colors';
import { MetadataFilter } from 'types/metadata';

export type AssetTypeOption = 'Images' | 'Videos' | 'All content';

interface TextSearchProps<T extends string> {
  setTextQuery: (textQuery: string) => void;
  textQuery: string;
  assetType?: AssetTypeOption;
  datasetId: string;
  setAssetType?: (at: AssetTypeOption | undefined) => void;
  setSearchActive?: (active: boolean) => void;
  error?: string;
  searchMode?: T;
  setSearchMode?: (searchMode: T) => void;
  searchModeOptions?: DropdownOption<T>[];
  searchContainerClassName?: string;
  isLoading: boolean;
  isRefetching: boolean;
  isLoaded: boolean;
  executeSearch: (shouldRefetch: boolean) => void;
  clearSearch: () => void;
  placeholder?: (type: AssetTypeOption | undefined) => void;
  metadataFilters?: MetadataFilter[];
  setMetadataFilters?: (metadataFilter: MetadataFilter[]) => void;
  selectedConcepts?: SelectedOption[];
  setSelectedConcepts?: (selectedConcepts: SelectedOption[]) => void;
  setIsUsingSearchEndpoint?: (isUsingSearchEndpoint: boolean) => void;
  setDatasetViewAssetType?: (datasetViewAssetType: AssetTypeOption) => void;
  isUsingSearchEndpoint?: boolean;
  searchActive?: boolean;
  datasetViewAssetType?: AssetTypeOption;
  dataTestId?: string;
}

const TextSearch = function TextSearch<T extends string>({
  assetType,
  datasetId,
  setAssetType,
  setSearchActive,
  error,
  searchMode,
  setSearchMode,
  searchModeOptions,
  searchContainerClassName,
  isLoading,
  isRefetching,
  isLoaded,
  executeSearch,
  clearSearch,
  selectedConcepts,
  setSelectedConcepts,
  setIsUsingSearchEndpoint,
  setDatasetViewAssetType,
  isUsingSearchEndpoint,
  searchActive,
  metadataFilters,
  setMetadataFilters,
  setTextQuery,
  textQuery,
  placeholder,
  datasetViewAssetType,
  dataTestId,
}: TextSearchProps<T>) {
  const hasNewCustomMetadataEnabled = evaluateFlag(
    FeatureFlag.ENABLE_V0_CUSTOM_METADATA,
  );
  const input = useRef<HTMLInputElement | null>();
  const { data: datasetData } = useGetDatasetById(datasetId);
  const { data: metadata } = useFetchMetadataFilters(datasetId);
  const metadataElements = metadata?.data ?? [];

  useEffect(() => {
    setSearchActive?.(isLoaded);
  }, [isLoaded]);

  const hasNoSearchboxParams = Boolean(
    !textQuery.trim() && selectedConcepts?.length === 0,
  );

  const hasActiveMetadataFilters =
    metadataFilters && metadataFilters.length > 0;

  useEffect(() => {
    setIsUsingSearchEndpoint?.(!hasNoSearchboxParams);
  }, [hasNoSearchboxParams]);

  const handleAssetTypeChange = (val) => {
    if (!isUsingSearchEndpoint && !searchActive && setDatasetViewAssetType) {
      setDatasetViewAssetType?.(val);
    } else {
      setAssetType?.(val);
    }
  };

  const handleRemoveMetadataFilter = (option) => {
    if (!metadataFilters) return;

    const selectedFilters = metadataFilters.filter(
      (filter) => filter?.value !== option.value,
    );

    if (selectedFilters.length >= 1) {
      executeSearch(true);
    } else if (!hasNewCustomMetadataEnabled) {
      clearSearch();
    }

    setMetadataFilters?.(selectedFilters);
  };

  const searchModeCount = searchModeOptions?.length ?? 0;
  const isIndicatorVisible = isLoading || isRefetching;

  const searchboxLoadingIndicator = useMemo(
    () => Boolean(!hasActiveMetadataFilters && !hasNoSearchboxParams),
    [isIndicatorVisible, hasActiveMetadataFilters, hasNoSearchboxParams],
  );

  const displayClearInSearchBox =
    isLoaded && !isLoading && !isRefetching && !hasNoSearchboxParams;

  const isCustomMetadataDisabled = Boolean(
    !hasNoSearchboxParams ||
      datasetViewAssetType !== 'All content' ||
      assetType !== 'All content',
  );

  const getSearchPlaceholder = () => {
    const defaultPlaceholder = `Search for anything in ${datasetData?.name} ...`;
    const metadataFilterMessage =
      'Text search is not available when filtering for custom metadata at this time.';

    if (hasNewCustomMetadataEnabled) {
      return placeholder?.(assetType) || defaultPlaceholder;
    }

    return (
      placeholder?.(assetType) ||
      (hasActiveMetadataFilters ? metadataFilterMessage : defaultPlaceholder)
    );
  };

  const hasNoMetadataV0DisabledBehavior =
    !hasNewCustomMetadataEnabled && hasActiveMetadataFilters;

  return (
    <div className={searchContainerClassName}>
      <SearchContainer
        searchMode={searchMode}
        setSearchMode={setSearchMode}
        searchModeOptions={searchModeOptions}
        executeSearch={() => executeSearch(true)}
        buttonDisabled={hasNoSearchboxParams}
        areaDisabled={hasNoMetadataV0DisabledBehavior}
        footer={
          <ConceptCombobox
            isDisabled={hasNoMetadataV0DisabledBehavior}
            datasetId={datasetId}
            selectedConcepts={selectedConcepts || []}
            setSelectedConcepts={setSelectedConcepts!}
          />
        }
        dropdowns={
          <>
            {searchMode === 'visual' && (
              <Dropdown
                className="shrink-0 border border-solid border-grey-500 rounded-md text-gray-700"
                options={[
                  { label: 'All content', value: 'All content' },
                  { label: 'Images', value: 'Images' },
                  { label: 'Videos', value: 'Videos' },
                ]}
                onChange={(val) => handleAssetTypeChange(val)}
                selected={assetType}
                buttonStyle="rounded-md h-full border-0"
                buttonShadow=""
                size="small"
                dataTestId="dataset-asset-type-dropdown"
              />
            )}
            {searchMode === 'audio' && (
              <Dropdown
                className="w-30 shrink-0 border border-solid border-grey-500 rounded-md text-gray-700"
                disabled
                options={[{ label: 'Videos', value: 'Videos' }]}
                onChange={() => null}
                selected="Videos"
                buttonStyle="rounded-md h-full border-0"
                buttonShadow=""
                size="small"
              />
            )}
            {metadataElements.length > 0 && (
              <CustomMetadata
                isDisabled={
                  !hasNewCustomMetadataEnabled && isCustomMetadataDisabled
                }
                executeSearch={executeSearch}
                metadataKeys={metadataElements}
                metadataFilters={metadataFilters || []}
                setMetadataFilters={setMetadataFilters!}
              />
            )}
            {isIndicatorVisible && !searchboxLoadingIndicator && (
              <div className="h-5 w-5">
                <ClipLoader
                  cssOverride={{ textAlign: 'center' }}
                  color={colors.green['500']}
                  loading
                  size={20}
                  speedMultiplier={0.75}
                />
              </div>
            )}
          </>
        }
      >
        <div className="relative grow items-center px-1">
          <input
            autoComplete="off"
            className={classNames(
              'h-12 w-full border-0 bg-transparent  text-gray-800 placeholder-gray-500 focus:ring-0 sm:text-sm focus:outline-none',
              {
                'pr-3': !isLoaded,
                'pr-10': isLoaded,
                'placeholder-slate-900': hasActiveMetadataFilters,
              },
              searchModeCount < 2 && 'rounded-tl-xl rounded-bl-xl',
            )}
            id="search"
            name="search"
            disabled={hasNoMetadataV0DisabledBehavior}
            onChange={(event) => setTextQuery?.(event.target.value)}
            onKeyUp={(event) => {
              if (event.code === 'Enter' && textQuery !== '') {
                executeSearch(true);
              }
              if (event.code === 'Escape') {
                clearSearch();
                setTextQuery?.('');
              }
            }}
            placeholder={getSearchPlaceholder()}
            ref={(r) => {
              input.current = r;
            }}
            value={textQuery}
            data-cy={`${dataTestId}-search-input`}
          />
          {isIndicatorVisible && searchboxLoadingIndicator && (
            <div className="absolute top-4 right-3 h-5 w-5">
              <ClipLoader
                cssOverride={{ textAlign: 'center' }}
                color={colors.green['500']}
                loading
                size={20}
                speedMultiplier={0.75}
              />
            </div>
          )}
          {displayClearInSearchBox && (
            <div className="absolute top-2 right-3">
              <IconButton
                Icon={XMarkIcon}
                onClick={() => {
                  clearSearch();
                  setTextQuery?.('');
                  input.current?.focus();
                }}
                fill="text-gray-800"
                size="small"
                rounded="full"
                tooltip="Clear"
              />
            </div>
          )}
        </div>
      </SearchContainer>
      {metadataFilters && metadataFilters.length > 0 && (
        <div className="mt-3 flex flex-row items-center gap-2 flex-wrap">
          {metadataFilters?.map((filter) => (
            <Button
              onClick={() => handleRemoveMetadataFilter(filter)}
              type="button"
              buttonStyle="secondary"
              color="bg-slate-100"
              hoverColor="hover:bg-slate-200"
              size="small"
              rightIcon={XMarkIcon}
              key={`${filter.key}-${filter.value}`}
            >
              <span className="mr-2 text-slate-400">{filter.key}</span>
              {filter.value}
            </Button>
          ))}
          <Button
            className="!px-1"
            onClick={() => clearSearch()}
            buttonStyle="text"
          >
            Clear filters
          </Button>
        </div>
      )}
      {Boolean(error) && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

TextSearch.defaultProps = {
  placeholder: undefined,
  selectedConcepts: undefined,
  setSelectedConcepts: undefined,
  setSearchActive: undefined,
  assetType: undefined,
  setAssetType: undefined,
  error: undefined,
  searchContainerClassName: undefined,
  searchMode: undefined,
  setSearchMode: undefined,
  searchModeOptions: undefined,
  setIsUsingSearchEndpoint: undefined,
  setDatasetViewAssetType: undefined,
  isUsingSearchEndpoint: false,
  searchActive: false,
  metadataFilters: undefined,
  setMetadataFilters: undefined,
  datasetViewAssetType: undefined,
  dataTestId: undefined,
};

export default TextSearch;
