import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import Dropdown from 'components/Dropdown';
import PageButton from 'components/Pagination/PageButton';
import PageDotsSeparator from 'components/Pagination/PageDotsSeparator';
import React from 'react';
import { TablePageSize } from 'types/table';

interface PaginationProps {
  activePage: number;
  pageSize: TablePageSize;
  total: number;
  setPageSize?: (pageSize: TablePageSize) => void;
  goToPage: (page: number) => void;
  showPaginationLabel?: boolean;
  allowChangePageSize?: boolean;
}

const numRowOptions = [
  {
    label: '3',
    value: '3',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
  {
    label: '200',
    value: '200',
  },
];

// Min number of pages required to show ellipsis in any view
const ELLIPSIS_MIN = 8;

const Pagination: React.FC<PaginationProps> = function Pagination({
  activePage,
  pageSize,
  total,
  setPageSize,
  goToPage: goToPageProp,
  showPaginationLabel,
  allowChangePageSize,
}: PaginationProps) {
  const numPages = Math.ceil(total / pageSize);

  const showMiddleEllipsis =
    numPages >= ELLIPSIS_MIN && (activePage <= 2 || activePage >= numPages - 1);

  const goToPage = (p: number) => {
    if (p !== activePage) {
      goToPageProp(p);
    }
  };

  let pageButtonNumber2 = numPages < ELLIPSIS_MIN ? 2 : undefined;
  let pageButtonNumber3 = numPages < ELLIPSIS_MIN ? 3 : activePage - 1;
  let pageButtonNumber4 = numPages < ELLIPSIS_MIN ? 4 : activePage;
  let pageButtonNumber5 = numPages < ELLIPSIS_MIN ? 5 : activePage + 1;
  let pageButtonNumber6 = numPages < ELLIPSIS_MIN ? 6 : undefined;

  if (showMiddleEllipsis) {
    pageButtonNumber2 = 2;
    pageButtonNumber3 = 3;
    pageButtonNumber4 = undefined as any;
    pageButtonNumber5 = numPages - 2;
    pageButtonNumber6 = numPages - 1;
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          type="button"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          type="button"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex flex-row items-center justify-between w-full">
          {showPaginationLabel ? (
            <p className="text-sm text-gray-700 pr-4 grow">
              Showing{' '}
              <span className="font-medium">
                {((activePage - 1) * pageSize + 1).toLocaleString()}
              </span>{' '}
              to{' '}
              <span className="font-medium">
                {Math.min(activePage * pageSize, total).toLocaleString()}
              </span>{' '}
              of <span className="font-medium">{total.toLocaleString()}</span>{' '}
              results
            </p>
          ) : undefined}
          <div className="flex grow-[2] justify-center px-2">
            {total > pageSize && (
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px "
                aria-label="Pagination"
              >
                <button
                  type="button"
                  disabled={activePage === 1}
                  className={classNames(
                    'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500',
                    {
                      'hover:bg-gray-50': activePage !== 1,
                      'opacity-50': activePage === 1,
                    },
                  )}
                  onClick={() => {
                    if (activePage > 1) {
                      goToPage(activePage - 1);
                    }
                  }}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>

                {/* 1 */}
                <PageButton
                  active={activePage === 1}
                  onClick={() => goToPage(1)}
                >
                  1
                </PageButton>

                {/* 2: 2 or ... */}
                {numPages >= 2 &&
                  (pageButtonNumber2 ? (
                    <PageButton
                      active={activePage === pageButtonNumber2}
                      onClick={() => goToPage(pageButtonNumber2 as number)}
                    >
                      {pageButtonNumber2}
                    </PageButton>
                  ) : (
                    <PageDotsSeparator />
                  ))}

                {/* 3: 3 or active page - 1 */}
                {numPages >= 3 && (
                  <PageButton
                    active={activePage === pageButtonNumber3}
                    onClick={() =>
                      pageButtonNumber3 && goToPage(pageButtonNumber3)
                    }
                  >
                    {pageButtonNumber3}
                  </PageButton>
                )}

                {/* 4: 4, middle ..., or active */}
                {numPages >= 4 &&
                  (pageButtonNumber4 ? (
                    <PageButton
                      active={activePage === pageButtonNumber4}
                      onClick={() => goToPage(pageButtonNumber4)}
                    >
                      {pageButtonNumber4}
                    </PageButton>
                  ) : (
                    <PageDotsSeparator />
                  ))}

                {/* 5: 5, numPages -2, or active page + 1 */}
                {numPages >= 5 && (
                  <PageButton
                    active={activePage === pageButtonNumber5}
                    onClick={() => goToPage(pageButtonNumber5)}
                  >
                    {pageButtonNumber5}
                  </PageButton>
                )}

                {/* 6, numPages - 1, or ... */}
                {numPages >= 6 &&
                  (pageButtonNumber6 ? (
                    <PageButton
                      active={activePage === pageButtonNumber6}
                      onClick={() => goToPage(pageButtonNumber6 as number)}
                    >
                      {pageButtonNumber6}
                    </PageButton>
                  ) : (
                    <PageDotsSeparator />
                  ))}

                {/* 7 */}
                {numPages >= 7 && (
                  <PageButton
                    active={activePage === numPages}
                    onClick={() => goToPage(numPages)}
                  >
                    {numPages}
                  </PageButton>
                )}

                <button
                  type="button"
                  className={classNames(
                    'relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500',
                    {
                      'hover:bg-gray-50': activePage !== numPages,
                      'opacity-50': activePage === numPages,
                    },
                  )}
                  disabled={activePage === numPages}
                  onClick={() => {
                    if (activePage < numPages) {
                      goToPage(activePage + 1);
                    }
                  }}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            )}
          </div>
          {allowChangePageSize ? (
            <div className="flex flex-row items-center pr-4 text-sm grow justify-end">
              <span className="pr-2">Show</span>
              <Dropdown
                label=""
                options={numRowOptions}
                selected={`${pageSize}`}
                dropdownStyle={'compressed' as any}
                menuPosition="top"
                onChange={(value: string) => {
                  const size = parseInt(value, 10);
                  if (!Number.isNaN(size)) {
                    setPageSize?.(size as TablePageSize);
                  }
                }}
              />
              <span className="pl-2">rows at once</span>
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  setPageSize: undefined,
  showPaginationLabel: true,
  allowChangePageSize: true,
};

export default Pagination;
