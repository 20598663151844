import React from 'react';
import { Column } from 'api/generated';
import ActionsMenu from 'components/ActionsMenu';
import { TableAction, TableRow } from 'components/Table/types';
import { AppendedCellContent } from 'types/table';
import TableBodyCell from './TableBodyCell';

interface TableBodyRowProps<T extends TableRow> {
  row: T;
  idKey: string;
  columns: Column[];
  hiddenColumns: Set<string> | undefined;
  actions: TableAction<T>[] | undefined;
  getStatusClassName: (status: string) => string;
  onClickImagePreview?: (data: TableRow) => void;
  className?: string;
  appendedCellContent?: AppendedCellContent;
}

const TableBodyRow = function TableBodyRow<T extends TableRow>({
  row,
  idKey,
  columns,
  hiddenColumns,
  actions,
  getStatusClassName,
  onClickImagePreview,
  className,
  appendedCellContent,
}: TableBodyRowProps<T>) {
  const visibleActions = actions?.filter(
    (action) => !action.dynamicHide?.(row),
  );

  return (
    <tr
      key={row?.data[idKey]}
      className={className}
      data-cy={`table-row-${row.row}`}
    >
      {columns
        ?.filter((col) => !hiddenColumns?.has(col.key))
        .map((col, idx) => {
          let cellValue;
          if (col.keyPath) {
            cellValue = row.data;
            col.keyPath.forEach((key) => {
              cellValue = cellValue[key];
            });
          } else {
            cellValue = row.data[col.key];
          }

          return (
            <TableBodyCell
              onClickImagePreview={onClickImagePreview}
              getStatusClassName={getStatusClassName}
              cellValue={cellValue}
              key={col.key}
              column={col}
              row={row}
              isLastCell={idx === (columns?.length || 0) - 1}
              cellId={`${row?.data[idKey]}-${col.key}`}
              appendedCellContent={appendedCellContent}
            />
          );
        })}
      {visibleActions && Boolean(visibleActions.length) && (
        <td className="whitespace-nowrap text-sm font-medium text-ellipsis overflow-visible px-4 py-3 text-right shrink">
          <ActionsMenu
            dataTestAccessor={row?.data[idKey]}
            actions={visibleActions.map((action) => ({
              ...action,
              action: () => action?.action?.(row),
            }))}
            insideScrollableContainer
          />
        </td>
      )}
    </tr>
  );
};

TableBodyRow.defaultProps = {
  appendedCellContent: undefined,
  onClickImagePreview: undefined,
  className: undefined,
};

export default TableBodyRow;
