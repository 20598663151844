import classNames from 'classnames';
import FadeTransition from 'components/FadeTransition';
import SidebarContext from 'context/SidebarContext';
import React, {
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import PageHeader, { PageHeaderProps } from './PageHeader';

function filteredClassNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface MainProps extends PageHeaderProps {
  leftSidebar?: ReactElement<any, any>;
  rightSidebar?: ReactElement<any, any>;
  mainClassName?: string;
}

const Main: React.FunctionComponent<PropsWithChildren<MainProps>> =
  function Main({
    actions,
    backButtonTo,
    breadcrumbs,
    title,
    children,
    options,
    leftSidebar,
    rightSidebar,
    mainClassName,
    loading,
  }) {
    const mainRef = useRef<HTMLDivElement | null>();
    const {
      rightSidebarOpen,
      setRightSidebarOpen,
      leftSidebarOpen,
      setLeftSidebarOpen,
    } = useContext(SidebarContext);
    const timeout = useRef<any>();

    useEffect(() => {
      if ((leftSidebarOpen || rightSidebarOpen) && mainRef.current) {
        mainRef.current.style.width = `${mainRef.current?.offsetWidth}px`;
        timeout.current = setTimeout(() => {
          if (mainRef.current) {
            mainRef.current.style.width = '';
          }
        }, 100);
      }
    }, [leftSidebarOpen, rightSidebarOpen]);

    useEffect(
      () => () => {
        clearTimeout(timeout.current);
        setRightSidebarOpen(false);
        setLeftSidebarOpen(false);
      },
      [],
    );

    return (
      <FadeTransition show appear className="h-full">
        <main className="flex transition-all w-full pb-8 h-full">
          <div id="left-sidebar" className="shrink shadow-md">
            {ReactDOM.createPortal(
              leftSidebar,
              document.getElementById('c-left-sidebar')!,
            )}
          </div>
          <div
            ref={(ref) => {
              mainRef.current = ref;
            }}
            className={filteredClassNames(
              'flex-1 py-3 px-2 sm:px-4 lg:px-8 h-full overflow-x-hidden overflow-y-hidden mx-auto transition-all duration-300',
              mainClassName,
            )}
          >
            {/* This m-1 wrapper is necessary so shadows & borders don't get cut off. */}
            <div className="m-1">
              {title && (
                <header className="2xl:max-w-8xl mx-auto pb-6">
                  <PageHeader
                    actions={actions}
                    backButtonTo={backButtonTo}
                    breadcrumbs={breadcrumbs}
                    title={title}
                    options={options}
                    loading={loading}
                  />
                </header>
              )}
              {children}
            </div>
          </div>
          <div
            id="right-sidebar"
            className={classNames('shrink shadow-md bg-yellow-200')}
          >
            {ReactDOM.createPortal(
              rightSidebar,
              document.getElementById('c-right-sidebar')!,
            )}
          </div>
        </main>
      </FadeTransition>
    );
  };

Main.defaultProps = {
  breadcrumbs: undefined,
  actions: undefined,
  leftSidebar: undefined,
  rightSidebar: undefined,
  mainClassName: undefined,
  loading: false,
} as MainProps;

export default Main;
