import classNames from 'classnames';
import React from 'react';

interface EmptyStateProps {
  primaryText?: string;
  message: string;
  Icon: any;
  iconClassName?: string;
}

const EmptyState = function EmptyState({
  primaryText,
  message,
  Icon,
  iconClassName,
}: EmptyStateProps) {
  return (
    <div className="w-full gap-3 flex items-center justify-center flex-col pt-8">
      <Icon className={classNames(iconClassName)} />
      <h3 className="text-gray-400 font-medium text-lg">
        {primaryText || 'Nothing to see here (yet)!'}
      </h3>
      <p className="text-sm text-gray-600">{message}</p>
    </div>
  );
};

EmptyState.defaultProps = {
  primaryText: undefined,
  iconClassName: undefined,
};

export default EmptyState;
