import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import SidebarContext from 'context/SidebarContext';
import './index.scss';
import { HistorySidebarProps } from 'pages/queries/HistorySidebar/types';
import HistorySidebarContent from 'pages/queries/HistorySidebar/HistorySidebarContenxt';

const HistorySidebar: React.FunctionComponent<HistorySidebarProps> =
  function HistorySidebar({
    selectedQueryId,
    selectQuery,
    selectedTab,
  }: HistorySidebarProps) {
    const [hasClosed, setHasClosed] = useState(false);
    const navigate = useNavigate();

    const { leftSidebarOpen, setLeftSidebarOpen } = useContext(SidebarContext);

    useEffect(
      () => () => {
        setLeftSidebarOpen(false);
      },
      [],
    );

    const onClose = () => {
      if (!hasClosed) setHasClosed(true);
      navigate('/queries');
    };

    return (
      <Transition.Root show={leftSidebarOpen} className="h-full min-h-fullnav">
        <Transition.Child
          className="h-full transition-width"
          enter="transition ease-in-out duration-300 transform"
          enterFrom="w-0"
          enterTo="md:w-96 xl:w-96"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="md:w-96 xl:w-96"
          leaveTo="w-0"
        >
          <Transition.Child
            className="relative md:w-96 xl:w-96 bg-white h-full"
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-[-100%]"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-[-100%]"
          >
            <div className="h-full flex-1 flex flex-col">
              <Transition.Child
                enter="ease-in-out duration-300 delay-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 fill-gray-900"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <HistorySidebarContent
                selectedQueryId={selectedQueryId}
                selectQuery={selectQuery}
                selectedTab={selectedTab}
              />
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Placeholder element to force sidebar to shrink to fit close icon */}
          </div>
        </Transition.Child>
      </Transition.Root>
    );
  };

export default HistorySidebar;
