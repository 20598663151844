import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { AssetResponse, AssetType, ImageSizeEnum } from 'api/generated';
import IconButton from 'components/IconButton';
import ProtectedVideo from 'components/video/ProtectedVideo';
import React, { Fragment, useRef } from 'react';
import ProtectedImage from './Image/ProtectedImage';

interface FullScreenAssetModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  asset: Partial<AssetResponse>;
  videoDisplayMode?: 'shot' | 'full';
}

const FullScreenAssetModal: React.FC<FullScreenAssetModalProps> = ({
  asset,
  open,
  setOpen,
  videoDisplayMode,
}) => {
  const cancelButtonRef = useRef(null);

  const isVideo = asset?.assetType === AssetType.Keyframe && asset.video?.url;
  const isImage = asset?.assetType === AssetType.Image;

  return (
    <Transition.Root show={open} as="div" className="absolute">
      <HeadlessDialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex w-full h-full items-end justify-center p-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <HeadlessDialog.Panel className="flex flex-col relative h-full w-full rounded-lg bg-white overflow-hidden">
                <div className="h-12 flex items-center justify-end pr-2">
                  <IconButton
                    alt="close-modal"
                    onClick={() => setOpen(false)}
                    Icon={XMarkIcon}
                    size="medium"
                    tooltip="Close"
                  />
                </div>
                <div className="bg-slate-200 grow flex justify-center h-[calc(100%-4rem)] items-center">
                  {isImage && asset.previewImages?.regular?.url && (
                    <ProtectedImage
                      url={asset.previewImages?.full?.url || ''}
                      sensitive={false}
                      className="h-full w-auto !static min-h-64"
                      size={ImageSizeEnum.Full}
                      coactiveImageId={asset.coactiveImageId}
                      placeholder="black"
                      preventHeightJump
                    />
                  )}
                  {isVideo && asset.previewImages && asset.video?.url && (
                    <ProtectedVideo
                      posterImageUrl={asset.previewImages.regular.url}
                      url={asset.video.url}
                      startTime={
                        videoDisplayMode === 'full'
                          ? undefined
                          : asset.audioSegment?.startTimeMs ??
                            asset.shot?.startTimeMs
                      }
                      endTime={
                        videoDisplayMode === 'full'
                          ? undefined
                          : asset.audioSegment?.endTimeMs ??
                            asset.shot?.endTimeMs
                      }
                      className="max-h-full"
                    />
                  )}
                </div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
};

FullScreenAssetModal.defaultProps = {
  videoDisplayMode: 'shot',
};

export default FullScreenAssetModal;
