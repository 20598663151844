import { PhotoIcon, SpeakerWaveIcon } from '@heroicons/react/24/solid';
import {
  AssetResponse,
  SearchType,
  VisualPrompt,
  SimilaritySearchResponse,
} from 'api/generated';
import TextSearchResults from 'components/DatasetSearch/DatasetTextSearch/TextSearchResults';
import { SwitchComponent } from 'components/SwitchComponent';
import { useGetTopContent } from 'queries/dynamic-tags';
import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from 'tailwindcss/colors';
import { InfiniteData } from 'react-query';
import { EmptyErrorState } from './ErrorEmptyState';

const SEARCH_TYPE_TO_IDX = {
  [SearchType.Visual]: 0,
  [SearchType.Audio]: 1,
};

interface DynamicTagsTopContentProps {
  isAudioSearchable: boolean;
  datasetId: string;
  textPrompts: string[];
  visualPrompts: VisualPrompt[];
  onAssetClick: (asset: AssetResponse) => void;
}

const DynamicTagsTopContent: React.FC<DynamicTagsTopContentProps> = ({
  isAudioSearchable,
  datasetId,
  onAssetClick,
  textPrompts,
  visualPrompts,
}: DynamicTagsTopContentProps) => {
  const [searchType, setSearchType] = useState<SearchType>(SearchType.Visual);
  const [previousData, setPreviousData] =
    useState<InfiniteData<SimilaritySearchResponse>>();
  const { data, isLoading, isRefetching, isError } = useGetTopContent(
    datasetId,
    textPrompts,
    visualPrompts,
    searchType,
  );

  // Update previous data when new data is successfully fetched
  useEffect(() => {
    if (data) {
      setPreviousData(data);
    }
  }, [data]);

  const resultOptions = [
    {
      label: 'Best visual match',
      icon: PhotoIcon,
      onClick: () => setSearchType(SearchType.Visual),
    },
    {
      label: 'Best audio match',
      icon: SpeakerWaveIcon,
      onClick: () => setSearchType(SearchType.Audio),
    },
  ];

  const tileFooter = (img: AssetResponse) => {
    if (!img.similaritySearchScore) {
      return <div />;
    }
    // TODO change round value to 4 after differing scores issue is solved
    const roundedScore = img.similaritySearchScore?.toFixed(2);
    return (
      <div className="flex justify-between w-full px-3 py-2 text-sm">
        <p>Probability score</p>
        <p>{roundedScore}</p>
      </div>
    );
  };

  return (
    <div className="mt-8 space-y-2">
      <div className="flex flex-row items-center gap-4">
        <h2 className="text-lg font-bold text-gray-800">Top content</h2>
        {isLoading && (
          <ClipLoader
            color={colors.emerald['500']}
            loading
            size={25}
            speedMultiplier={0.75}
          />
        )}
      </div>
      {isAudioSearchable && (
        <SwitchComponent
          options={resultOptions}
          selectedOptionIndex={SEARCH_TYPE_TO_IDX[searchType]}
        />
      )}
      {isError && <EmptyErrorState itemWithError="top content" />}
      {previousData && !isError && (
        <TextSearchResults
          onClick={onAssetClick}
          results={previousData!}
          isLoading={false}
          isRefetching={isRefetching}
          clearSearch={() => null}
          assetType="Images"
          hideClearSearch
          hideHeader
          isLabelingResults={false}
          tileFooter={tileFooter}
        />
      )}
    </div>
  );
};

export default DynamicTagsTopContent;
